var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', [_c('div', [_c('label', {
    attrs: {
      "for": "writer-name"
    }
  }, [_vm._v("작성자 이름")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.writer.name,
      expression: "board.writer.name"
    }],
    attrs: {
      "type": "text",
      "id": "writer-name"
    },
    domProps: {
      "value": _vm.board.writer.name
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board.writer, "name", $event.target.value);
      }, function ($event) {
        return _vm.$emit('input', _vm.board);
      }]
    }
  })]), _c('div', [_c('label', {
    attrs: {
      "for": "subject"
    }
  }, [_vm._v("제목")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.subject,
      expression: "board.subject"
    }],
    attrs: {
      "type": "text",
      "id": "subject"
    },
    domProps: {
      "value": _vm.board.subject
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board, "subject", $event.target.value);
      }, function ($event) {
        return _vm.$emit('input', _vm.board);
      }]
    }
  })]), _c('div', [_c('naver-smarteditor', {
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.board);
      }
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1), _c('div', [_c('label', {
    attrs: {
      "for": "password"
    }
  }, [_vm._v("비밀번호")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.password,
      expression: "board.password"
    }],
    attrs: {
      "type": "password",
      "id": "password",
      "maxlength": "6"
    },
    domProps: {
      "value": _vm.board.password
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board, "password", $event.target.value);
      }, function ($event) {
        return _vm.$emit('input', _vm.board);
      }]
    }
  })])])]), _c('div', [_c('div', [_c('a', {
    staticClass: "button button--border",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', [_vm._v("취소")])]), _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, [_c('span', [_vm._v("확인")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }